
  import Vue from 'vue';
  import * as api from '@/api';
  import { ElForm } from 'element-ui/types/form';
  import RolePermission from '../role/RolePermission.vue';

  export default Vue.extend({
    name: 'UserAdd',
    components: { RolePermission },
    data() {
      const self: any = this;
      const validatePass = (rule, value, callback) => {
        if (!self.form.password && !self.editMode) {
          callback('必填');
        }
        self.$refs.userInfo.validateField('checkPassword');
        callback();
      };
      const validatePass2 = (rule, value, callback) => {
        if (!self.form.checkPassword && !self.editMode) {
          callback('必填');
        } else if (self.form.checkPassword !== self.form.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        form: {
          userName: '',
          email: '',
          roleId: '',
          brandId: '',
          nickName: '',
          password: '',
          checkPassword: '',
          description: '',
        },
        roleList: [] as api.Role[],
        brandList: [] as api.Brand[],
        permissionTree: [] as api.RolePermissionTreeNode[],
        saving: false,
        hasEdited: false,
        rules: {
          userName: [
            { required: true, message: '必填', trigger: 'blur' },
          ],
          email: [
            { required: true, message: '必填', trigger: 'blur' },
            { pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/, message: '邮箱格式错误', trigger: 'blur' },
          ],
          roleId: [
            { required: true, message: '必填', trigger: 'change' },
          ],
          brandId: [
            { required: true, message: '必填', trigger: 'change' },
          ],
          password: [
            { validator: validatePass, trigger: 'blur' },
          ],
          checkPassword: [
            { validator: validatePass2, trigger: 'blur' },
          ],
        },
      };
    },
    computed: {
      permissionFlatted(): { [id: string]: api.RolePermissionTreeNode } {
        const ret = {};
        const treeFlat = (tree: api.RolePermissionTreeNode) => {
          ret[tree.id] = tree;
          tree.children.forEach((c) => treeFlat(c));
        };
        this.permissionTree.forEach((node) => treeFlat(node));
        return ret;
      },
      editMode(): boolean {
        return !!this.$store.state.editUser;
      },
      editUserId(): string {
        return this.$store.state.editUser?.userId;
      },
    },
    watch: {
      async roleId(id) {
        const res = await api.getRolePermissionTree({ id });
        if (!api.error(res)) {
          this.permissionTree = res.data.roots;
        }
      },
    },
    async created() {
      this.$store.commit('breadcrumbList', [
        {
          name: '用户列表',
          path: 'list',
        },
        {
          name: this.$store.state.editUser ? '编辑用户' : '添加用户',
          path: this.$store.state.editUser ? 'edit' : 'add',
        },
      ]);
      const roleRes = await api.getRoleList();
      if (!api.error(roleRes)) {
        this.roleList = roleRes.data;
      }
      const brandRes = await api.getBrandList({ MaxResultCount: 999 });
      if (!api.error(brandRes)) {
        this.brandList = brandRes.data.items;
      }
      if (this.editMode) {
        this.form = {
          ...this.$store.state.editUser,
          brandId: this.$store.state.editUser.brands === '全部' ? '#' : this.$store.state.editUser.brandIds[0], // 坑
          roleId: this.$store.state.editUser.roleIds[0],
        };
      }
    },
    methods: {
      resetPermission() {
        (this.$refs.rolePermission as any).reset();
      },
      cancel() {
        this.$router.back();
      },
      submit() {
        (this.$refs.userInfo as ElForm).validate(async (ok) => {
          if (ok) {
            this.saving = true;
            const opt = {
              userName: this.form.userName,
              email: this.form.email,
              roleIds: [this.form.roleId],
              brandIds: this.form.brandId === '#' ? null : [this.form.brandId],
              nickName: this.form.nickName,
              password: this.form.password,
              description: this.form.description,
            };
            const res = this.editMode ? await api.editUser({ userId: this.editUserId, ...opt }) : await api.createUser(opt);
            this.saving = false;
            if (!api.error(res)) {
              this.$message({
                type: 'success',
                message: '保存成功',
              });
              this.$store.commit('editUser', null);
              this.$router.back();
            } else {
              this.$message({
                type: 'error',
                message: res.error.message,
              });
            }
          }
        });
      },
    },
  });
